import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./styles.module.css"

const Technologies = ({ tags }) => {
  return (
    <div className={styles.tech}>
      <h2 className="text-2xl">Tools & Technologies</h2>
      <p className="block">The tech stack</p>
      <ul className={classnames(styles.grid, "grid p-0 text-sm")}>
        {tags.map(tag => (
          <li
            className="flex items-center justify-center text-center py-1 px-2 bg-gray-800 rounded text-gray-400"
            key={tag}
          >
            {tag}
          </li>
        ))}
      </ul>
    </div>
  )
}

Technologies.propTypes = {
  tags: PropTypes.array,
}

export default Technologies
